.SharedHeader {
  position: fixed;
  z-index: 99;
  height: $shared-header-height;
  width: 100%;
  top: $header-height-logo;
  background-color: $white;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  @media screen and (max-width: $sm-breakpoint) {
    top: $header-height-xs;
    height: $shared-header-height-xs;
  }

  .SharedHeader-sub-heading {
    font-size: $font-size-base;
    color: $text-dark-black;
    font-weight: $font-weight-medium;
    @media screen and (max-width: $sm-breakpoint) {
      font-size: $font-size-sm;
    }
  }

  .SharedHeader-sideline {
    width: 5px;
    height: 2.5rem;
    background-color: $purple;
    border-radius: 1.25rem;

    @media screen and (max-width: $sm-breakpoint) {
      height: 2rem;
    }
  }

  .SharedHeader-heading {
    font-size: $h3-font-size;
    font-weight: $font-weight-bold;
    color: $purple;
    @media screen and (max-width: $sm-breakpoint) {
      font-size: $font-size-lg;
    }
  }
  .organisation-logo-container {
    width: 20.688rem !important;
    height: 10.938rem !important;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: $sm-breakpoint) {
      max-width: 10.375rem !important;
      height: 5.485rem !important;
    }
    .organisation-logo {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .bottomspace-sharedheader {
    height: 30px;
    width: 100%;
    background-color: $white;
    @media screen and (max-width: $sm-breakpoint) {
      height: 18px;
    }
  }
}

//  New shared Header

.NewSharedHeader {
  position: relative;
  // z-index: 10 !important;
  height: 19.375rem !important;
  width: 100%;
  margin-top: 6.25rem !important;
  background-color: $dark-purple;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: $sm-breakpoint) {
    top: $header-height-xs;
    height: 14.563rem !important;
    margin-top: 0px !important;
  }

  .NewSharedHeader-sub-heading {
    font-size: $font-size-base;
    color: $text-dark-black;
    font-weight: $font-weight-medium;
    @media screen and (max-width: $sm-breakpoint) {
      // font-size: $font-size-sm;
      font-size: 2.25rem !important;
    }
  }

  .NewSharedHeader-sideline {
    width: 5px;
    height: 2.5rem;
    background-color: $dark-purple;
    border-radius: 1.25rem;

    @media screen and (max-width: $sm-breakpoint) {
      height: 2rem;
    }
  }

  .NewSharedHeader-heading {
    font-size: 3.375rem;
    font-weight: $font-weight-semibold;
    color: $white;
    margin-left: 3rem !important;
    line-height: 3.75rem;
    @media screen and (max-width: $sm-breakpoint) {
      font-size: 2.25rem !important;
      line-height: 2.5rem !important;
      margin-left: 0px !important;
      line-height: 2.5rem !important;
      margin-top: 1rem !important;
    }
  }
  .organisation-logo-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: 0.625rem;
    width: 20.688rem;
    height: 10.938rem;
    margin-right: 4.5rem !important;

    @media screen and (max-width: $sm-breakpoint) {
      max-width: 10.375rem !important;
      max-height: 5.485rem !important;
      height: 5.485rem !important;
    }

    .organisation-logo {
      object-fit: contain;
      border-radius: 0.625rem;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      width: 20.688rem;
      max-height: 10.938rem;

      @media screen and (max-width: $sm-breakpoint) {
        width: 10.375rem !important;
        max-height: 5.485rem !important;
        margin-right: 0 !important;
      }
    }
  }
  .bottomspace-sharedheader {
    height: 30px;
    width: 100%;
    border-radius: 20px;
    @media screen and (max-width: $sm-breakpoint) {
      height: 18px;
    }
  }

  .design-header {
    position: relative;
    z-index: 0;
  }

  .design-header::before {
    background: url("../../assets/images/vector-6.png");
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    display: block;
    width: 25.533rem;
    height: 21.863rem;
    right: 0;
    position: absolute;
    top: -34%;
    z-index: -2;
  }

  @media screen and (max-width: $sm-breakpoint) {
    .design-header::before {
      background: url("../../assets/images/vector-7.png") no-repeat;
      background-size: contain;
      right: -12%;
      top: -16%;
      width: 14.528rem !important;
      height: 11.97rem !important;
    }
  }
}
