.organisation-large-logo {
  height: 10.5rem;
  width: 100%;
  margin-top: 20rem;

  .organisation-logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media screen and (max-width: $md-breakpoint) {
    margin-top: 5rem;
  }
}

.no-plan-description {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .strong-heading {
    color: $purple-v2;
  }

  .bottom-header {
    background-color: $light-purple;
    width: 38rem;
    padding: 0rem 3.5rem 1.5rem 3.5rem;
    border-radius: 0.5rem;

    @media screen and (max-width: $sm-breakpoint) {
      width: 17rem;
      padding: 0rem 1rem 1.75rem 1rem;
    }

    @media screen and (max-width: $xs-breakpoint) {
      width: 14rem;
      padding: 0rem 0.5rem 1.5rem 0.5rem;
    }
  }
}

.back-img {
  height: 80vh;
  z-index: -9;
  margin-top: -35%;

  @media screen and (max-width: $sm-breakpoint) {
    height: 55vh;
    margin-top: -100%;
  }

  @media screen and (min-width: $sm-breakpoint) and (max-width: $md-breakpoint) {
    height: 60vh;
    margin-top: -80%;
  }
}

.back-img-sm {
  height: 31vh;
  z-index: -9 !important;
  margin-top: -15%;

  @media screen and (max-width: $lg-breakpoint) {
    height: 34vh;
    margin-top: -25%;
  }

  @media screen and (max-width: $md-breakpoint) {
    height: 33vh;
    margin-top: -31%;
  }

  @media screen and (max-width: $sm-breakpoint) {
    height: 40vh;
    margin-top: -61%;
  }

  @media screen and (max-width: $xs-breakpoint) {
    height: 35vh;
    margin-top: -100%;
  }
}

.plan-detail-container {
  width: 100%;
  border: 1px solid #d8d4d3;
  background-color: $white;

  .banner-container {
    width: 100%;
    height: 11.25rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 1.875rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media screen and (max-width: $sm-breakpoint) {
      padding: 0.875rem;
    }

    .plan-with-provider {
      width: 15.937rem;
      height: 4.562rem;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      @media screen and (max-width: $md-breakpoint) {
        max-width: 80%;
        max-height: 80%;
      }

      @media screen and (max-width: $sm-breakpoint) {
        max-width: 70%;
        max-height: 70%;
      }
    }

    .plan-with-provider-text {
      font-size: 18px;
      color: #000;
      font-weight: 400;
      text-align: center;
      line-height: 1.36rem;

      @media screen and (max-width: $md-breakpoint) {
        font-size: 1rem;
        line-height: 1.19rem;
      }

      @media screen and (max-width: $sm-breakpoint) {
        font-size: 0.875rem;
        line-height: 1rem;
      }
    }

    .status-heading {
      font-size: 1.125rem;
      color: $white;
      font-weight: $font-weight-semibold !important;

      @media screen and (max-width: $sm-breakpoint) {
        font-size: $font-size-sm;
        margin-top: 0.8rem;
      }
    }

    .status-name {
      font-size: 1.125rem;
      color: $white;
      font-weight: $font-weight-bold;

      @media screen and (max-width: $sm-breakpoint) {
        font-size: $font-size-sm;
        margin-top: 0.8rem;
      }
    }
  }

  .info-container {
    background-color: $white;

    @media screen and (max-width: $sm-breakpoint) {
      flex-direction: column;
    }

    .align-left {
      @media screen and (max-width: $md-breakpoint) {
        align-items: flex-start !important;
        justify-content: flex-start !important;
      }
    }

    .plan {
      color: $special-gray !important;
      font-family: "Inter", sans-serif !important;
      font-size: 1rem !important;
      font-weight: $font-weight-medium !important;
      line-height: 1.3125rem !important;

      @media screen and (max-width: $xsm-breakpoint) {
        font-size: $font-size-sm !important;
      }

      @media screen and (max-width: $sx-breakpoint) {
        font-size: $font-size-xsm !important;
      }
    }

    .plan .value {
      font-size: 22px !important;
      line-height: 1.664rem !important;

      @media screen and (max-width: $xsm-breakpoint) {
        font-weight: $font-weight-bold !important;
        font-size: 1.175rem !important;
        line-height: 1.464rem !important;
      }

      @media screen and (max-width: $sx-breakpoint) {
        font-weight: $font-weight-bold !important;
        font-size: $font-family-base !important;
        line-height: 1rem !important;
      }
    }

    .icon-btn {
      font-weight: 300 !important;
      color: $purple-v2 !important;
      line-height: 1.212rem;
      font-family: "Inter", sans-serif !important;
      width: 19rem !important;
      border: 2px solid $purple-v2 !important;
      text-transform: none !important;
      border-radius: 0.5rem !important;

      @media screen and (max-width: $md-breakpoint) {
        max-width: 16rem;
        font-size: 0.7rem !important;
      }

      @media screen and (max-width: $sm-breakpoint) {
        max-width: 10rem;
        font-size: 0.5rem !important;
      }
    }
  }
}

.calculator_btn {
  // width: 100%; // Always full width

  @media screen and (max-width: $sm-breakpoint) {
    width: 100% !important; // Not needed, but keeps consistency
  }
}

.plan-guidance-image {
  text-align: center !important;
  margin-bottom: 0.938rem !important;
  @media screen and (max-width: $sm-breakpoint) {
    text-align: start !important;
  }
}

.contribute-image {
  width: 13.313rem !important;
  height: 12.625rem !important;
  @media screen and (max-width: $sm-breakpoint) {
    width: 7.111rem !important;
    height: 6.731rem !important;
  }
}

.plan-guidance-container {
  background-color: $white;
  border: 1px solid #d8d4d3;
  padding: 1.875rem;

  @media screen and (max-width: $sm-breakpoint) {
    padding: 0.875rem;
  }

  .plan-guidance-heading {
    font-size: 1.375rem;
    color: $purple-v2;
    font-weight: $font-weight-bold;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: $font-size-base;
    }
  }

  .plan-guidance-content {
    color: $black;
    font-size: $font-size-base;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: $font-size-sm;
    }
  }
}

.video-container {
  height: 29.5625rem;
  width: 100%;

  @media screen and (max-width: $sm-breakpoint) {
    height: 11.5625rem;
  }
}

.service-container {
  padding: 0 1rem;

  @media screen and (min-width: $sm-breakpoint) and (max-width: $md-breakpoint) {
    display: flex;
    justify-content: center;
  }

  .service-card {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .service-icon {
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;

      .icon {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .service-content {
      font-weight: $font-weight-bold;
      font-size: $font-size-base;
      color: $purple-v2;
      text-align: center;

      @media screen and (max-width: $sm-breakpoint) {
        font-size: $font-size-sm;
      }
    }
  }
}

.customer-service-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .customer-service-content {
    font-size: $font-size-base;
    color: $purple-v2;
    text-align: center;
    font-weight: bold;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: $font-size-sm;
    }
  }

  .customer-service-button {
    width: 10rem;
    height: 3.125rem;
    background-color: $purple;
    color: $white;
    border-radius: 0.5rem;
    text-transform: capitalize !important;

    &:hover {
      background-color: $purple;
    }
  }
}

.my-plan-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 11.125rem;
  width: 85.875;
  background: $purple;
  padding: 1rem;

  .my-plan-heading {
    color: $white;
    font-weight: bold;
    font-size: 1.25rem;
    margin-bottom: 3rem !important;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: 1rem !important;
    }
  }

  @media screen and (max-width: $sm-breakpoint) {
    height: 9.125rem;
  }
}

.cancel-plan-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $white;
  height: auto;
  min-height: 20rem;
  margin: 0 2rem;
  padding: 1rem;
  margin-top: -4rem !important;
  border: 1px solid $border-gray;
  text-align: center;

  @media screen and (max-width: $sm-breakpoint) {
    margin: 0 0.35rem;
    margin-top: -4rem !important;
    padding: 0.5rem !important;
  }
}

.cancel-plan-content-flex-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: $white;
  height: auto;
  min-height: 20rem;
  margin: 0 2rem;
  padding: 1rem;
  margin-top: -4rem !important;
  border: 1px solid $border-gray;

  @media screen and (max-width: $sm-breakpoint) {
    margin: 0 0.35rem;
    margin-top: -4rem !important;
    padding: 0.5rem !important;
  }

  .padding-content-heading {
    font-size: $font-size-lg;
    color: $purple-v2;
    padding: 0 1.875rem !important;
    font-weight: $font-weight-bold;

    @media screen and (max-width: $sm-breakpoint) {
      padding: 0 !important;
      font-size: $font-size-base;
    }
  }

  .padding-content {
    padding: 0 1.875rem !important;
    color: $black;

    @media screen and (max-width: $sm-breakpoint) {
      padding: 0 !important;
    }
  }
}

.width-restriction {
  min-width: fit-content !important;

  @media screen and (max-width: $sm-breakpoint) {
    margin: 0 0.25rem !important;
    margin-top: -4rem !important;
  }
}

.avc-amount-box {
  width: 100%;
  border: 1px solid #d8d4d3;
  min-height: 10rem;
  padding: 1rem;
}

.generic-heading-cotainer {
  width: 100%;
  height: 3.75rem;
  background: $purple;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.1rem 2rem;

  @media screen and (max-width: $sm-breakpoint) {
    padding: 0 0.3rem;
  }

  .generic-heading {
    color: $white;
    font-size: 1rem;
    font-weight: $font-weight-bold;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: $font-size-sm;
    }
  }
}

.flex-start {
  justify-content: flex-start !important;
  align-items: center !important;
}

.amend-form-tab {
  background-color: $light-purple !important;
  padding: 0.3rem 1rem;
  border-radius: 0.25rem;
  height: 3.65rem;

  @media screen and (max-width: $md-breakpoint) {
    height: auto;
  }

  .form-text {
    font-size: 14px;
  }

  .underline-text {
    text-decoration: underline;
  }
}

.amend-page-accordian {
  .summary {
    border: none !important;
    background-color: $light-purple !important;

    .MuiAccordionSummary-expandIconWrapper {
      color: $purple-v2 !important;
    }
  }

  .summary-text {
    font-weight: bold !important;
    color: $purple-v2 !important;
  }
}

.amendment-reason-box {
  padding: 1.3rem;
}

.amend-descripton-box {
  background-color: $moderate-gray;
  padding: 1rem;
}

.downlaod-application {
  flex-direction: row !important;
  & > button {
    padding: 16px;
    width: 56px;
    min-width: none !important;
    &:hover {
      background-color: #eee !important;
    }
  }

  & > .home-typography {
    font-size: 20px !important;
    line-height: 24px !important;
    font-weight: 600 !important;
    font-family: "Elza", "Inter", sans-serif !important;
    &:hover {
      color: $purple-v2;
    }
  }
}

.manage-my-shared-cost * {
  font-family: "Elza", "Inter", sans-serif !important;

  .currency-contribution {
    font-size: 16px !important;
    font-weight: 700 !important;
  }

  .payroll-table {
    margin-left: 53px;

    @media screen and (max-width: $md-breakpoint) {
      margin-left: 10px;
    }

    @media screen and (max-width: $sm-breakpoint) {
      margin-left: 0px !important;
    }
  }

  .currency {
    font-size: 22px;
    font-weight: 500;
    margin-left: 10px;
  }

  .amend-container {
    margin-left: 20px;

    @media screen and (max-width: $sm-breakpoint) {
      margin-left: 0px !important;
    }
    & > button {
      padding: 16px;
      width: 56px;
      &:hover {
        background-color: #eee;
      }
      min-width: none;
    }
  }
}

.new-plan-main-container {
  position: relative;
  // z-index: 0;

  @media screen and (max-width: $sm-breakpoint) {
    margin-top: 6rem !important;
  }
}

.new-plan-main-container::before {
  background: url("../../assets/images/vector-1.png");
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  display: block;
  width: 100%;
  height: 698px;
  left: -28px;
  position: absolute;
  top: -200px;
  z-index: -1;

  @media screen and (max-width: $sm-breakpoint) {
    width: 423px;
    height: 348px;
    rotate: 12.29deg;
    top: -200px;
  }
}

.plan-status-divider {
  position: relative;
  z-index: 0;
}

.plan-status-divider::before {
  // background: url("../../assets/images/vector-8.png");
  // background-repeat: no-repeat;
  // background-size: contain;
  // content: "";
  // display: block;
  // right: -28px;
  // position: absolute;
  // top: -60px;
  // z-index: -1;
  // width: 381px;
  // height: 476px;
  background: none !important;
}

@media screen and (max-width: $sm-breakpoint) {
  .plan-status-divider::before {
    background: none;
  }
}

.new-plan-detail-container {
  z-index: 0;
  position: relative;
  .banner-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 1.875rem 1.875rem 0.5rem 1.875rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media screen and (max-width: $sm-breakpoint) {
      padding: 0.275rem;
    }

    .plan-with-provider {
      width: 15.937rem;
      height: 4.562rem;
      background-color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      @media screen and (max-width: $md-breakpoint) {
        max-width: 80%;
        max-height: 80%;
      }

      @media screen and (max-width: $sm-breakpoint) {
        max-width: 70%;
        max-height: 70%;
      }
    }

    .plan-with-provider-text {
      font-size: 18px;
      color: #000;
      font-weight: 400;
      text-align: center;
      line-height: 1.36rem;

      @media screen and (max-width: $md-breakpoint) {
        font-size: 1rem;
        line-height: 1.19rem;
      }

      @media screen and (max-width: $sm-breakpoint) {
        font-size: 0.875rem;
        line-height: 1rem;
      }
    }

    .status-heading {
      font-size: 1.625rem;
      color: $black;
      font-weight: $font-weight-semibold !important;
      line-height: 1.95rem;
      margin-top: 2rem;

      @media screen and (max-width: $sm-breakpoint) {
        font-size: 1.125rem;
        margin-top: 0.8rem;
        line-height: 1.35;
      }
    }

    .status-name {
      font-size: 3.375rem;
      font-weight: $font-weight-semibold;
      font-family: "Elza", "Inter", sans-serif !important;
      line-height: 3.75rem;

      @media screen and (max-width: $sm-breakpoint) {
        font-size: 1.938rem;
        //margin-top: 0.8rem;
        line-height: 2.325rem;
      }
    }

    .awaiting-list {
      li,
      p {
        font-size: 1.375rem !important;
        line-height: 2.25rem !important;
        @media screen and (max-width: $sm-breakpoint) {
          font-size: 1.063rem !important;
          line-height: 1.875rem !important;
        }
      }
    }
  }

  .info-container {
    @media screen and (max-width: $sm-breakpoint) {
      flex-direction: column;
      //padding: 0.5rem;
    }

    .status-heading {
      font-size: 2.25rem;
      font-weight: $font-weight-semibold !important;
      color: $purple-v3;

      @media screen and (max-width: $sm-breakpoint) {
        font-size: 1.938rem !important;
      }
    }

    .plan-summary {
      box-shadow: 0px 0px 23px 0px #00000040;
      border-radius: 1.25rem;
      height: 14.188rem;
      background-color: $white;

      .amend-button {
        & > button {
          padding: 0px !important;
          min-width: 0px !important;
          width: 30px !important;
        }
      }

      @media screen and (max-width: $sm-breakpoint) {
        height: auto !important;
      }
    }

    .plan {
      font-size: 0.938rem !important;
      font-weight: $font-weight-normal !important;
      line-height: 1.125rem !important;

      @media screen and (max-width: $xsm-breakpoint) {
        font-size: 0.813rem !important;
        line-height: 0.975rem !important;
      }
    }

    .plan .value {
      font-size: 2.25rem !important;
      line-height: 2.5rem !important;
      font-weight: $font-weight-semibold !important;
      color: $purple !important;

      @media screen and (max-width: $sm-breakpoint) {
        font-size: 1.938rem !important;
        line-height: 2.325rem !important;
      }
    }

    .plan-actions {
      background-color: $purple !important;
      color: $white !important;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;

      .downlaod-application {
        flex-direction: row !important;
        align-items: flex-end;
        gap: 0px !important;

        & > button {
          border-bottom-left-radius: 20px;
          padding: 0px !important;
          min-width: 0px !important;
          width: 30px !important;
        }

        & > .home-typography {
          font-size: 1.125rem !important;
          line-height: 1.35rem !important;
          font-weight: $font-weight-semibold !important;
          font-family: "Elza", "Inter", sans-serif !important;
          &:hover {
            color: $purple-v2;
          }
          @media screen and (max-width: $sm-breakpoint) {
            font-size: 1rem !important;
            line-height: 1.2rem !important;
          }
        }
      }
      @media screen and (max-width: $sm-breakpoint) {
        flex-direction: column !important;
      }

      .cancle-button {
        & > button {
          font-weight: $font-weight-semibold !important;
          font-size: 1.125rem !important;
          line-height: 1.35rem !important;

          @media screen and (max-width: $sm-breakpoint) {
            font-size: 1rem !important;
            line-height: 1.2rem !important;
          }
        }
      }
    }

    .icon-btn {
      font-weight: 300 !important;
      color: $purple-v2 !important;
      line-height: 1.212rem;
      font-family: "Inter", sans-serif !important;
      width: 19rem !important;
      border: 2px solid $purple-v2 !important;
      text-transform: none !important;
      border-radius: 0.5rem !important;

      @media screen and (max-width: $md-breakpoint) {
        max-width: 16rem;
        font-size: 0.7rem !important;
      }

      @media screen and (max-width: $sm-breakpoint) {
        max-width: 10rem;
        font-size: 0.5rem !important;
      }
    }
  }

  .desktop-padding {
    @media screen and (max-width: $sm-breakpoint) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .faq-button {
    width: 20.938rem !important;
    height: 3.125rem !important;
    background-color: $purple !important;
    color: white !important;
    font-size: 1.25rem !important;
    font-weight: 31.25rem !important;
    text-transform: capitalize !important;
    border-radius: 0.375rem !important;
    border: 0.063rem !important;
    text-align: center;
    padding: 0.625rem !important;
    cursor: pointer;
    @media screen and (max-width: $sm-breakpoint) {
      width: 100% !important;
    }

    @media screen and (max-width: $xs-breakpoint) {
      font-size: 0.9rem !important;
    }

    &:hover {
      background-color: $lighter-puprle-v3 !important;
      color: $linear-background !important;
    }
  }

  .term-button {
    width: 20.938rem !important;
    height: 3.125rem !important;
    padding: 0.625rem !important;
    font-size: 1.25rem !important;
    font-weight: 31.25rem !important;
    border: 0.125rem solid $purple_v2 !important;
    color: $purple-v2 !important;
    text-transform: capitalize !important;
    border-radius: 0.5rem !important;
    text-align: center;
    padding: 0.5rem;
    cursor: pointer;

    @media screen and (max-width: $sm-breakpoint) {
      width: 100% !important;
    }

    @media screen and (max-width: $xs-breakpoint) {
      font-size: 0.9rem !important;
    }

    &:hover {
      color: $dark-purple !important;
    }
  }
}

.new-plan-guidance-image-container {
  text-align: center;
  margin-top: -1rem;

  .contribute-shape-image {
    width: 100%;
    max-width: 100%;
  }

  @media screen and (max-width: $sm-breakpoint) {
    display: none !important;
  }
}

.new-plan-guidance-container {
  padding: 4.875rem 2rem;
  background-color: #f4f0f9;

  @media screen and (max-width: $sm-breakpoint) {
    padding: 0.875rem;
    background-color: transparent !important;
  }

  .plan-guidance-heading {
    font-size: 2.25rem;
    color: $purple-v3;
    line-height: 2.5rem;
    font-weight: $font-weight-semibold;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: 1.938rem;
      line-height: 2.325rem;
    }
  }

  .plan-guidance-content {
    color: $text-black-v1;
    font-size: 1.375rem;
    font-weight: $font-weight-normal;
    line-height: 1.65rem;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: 1.125rem;
      line-height: 1.35rem;
    }
  }
}

.new-customer-service-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .customer-service-heading {
    font-size: 3.375rem;
    color: $dark-purple;
    font-weight: $font-weight-semibold;
    line-height: 3.75rem;
    @media screen and (max-width: $sm-breakpoint) {
      font-size: 2.25rem;
      line-height: 2.5rem;
    }
  }

  .customer-service-content {
    font-size: 1.938rem;
    text-align: center;
    font-weight: $font-weight-normal;
    font-family: "Elza";
    line-height: 2.325rem;
    color: $text-black-v1;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: 1.125rem;
      line-height: 1.35rem;
    }
  }

  .customer-service-button {
    width: 100%;
    height: 9.625rem !important;
    background-color: $purple;
    color: $white;
    border-radius: 1.25rem;
    cursor: pointer;

    &:hover {
      background-color: $purple-v2;
    }

    @media screen and (max-width: $sm-breakpoint) {
      width: 10rem;
      flex-direction: column;
      height: 10.375rem !important;
    }

    .button-heading {
      font-size: 1.938rem;
      line-height: 2.325rem;
      font-weight: $font-weight-semibold;
      @media screen and (max-width: $sm-breakpoint) {
        font-size: 1.125rem;
        line-height: 1.35rem;
      }
    }
    .value {
      font-weight: $font-weight-normal;
      font-size: 1.625rem;
      line-height: 1.95rem;
    }
    .contact-icon {
      width: 7.125rem;
      height: 7.125rem;

      @media screen and (max-width: $sm-breakpoint) {
        width: 5.25rem;
        height: 5.25rem;
      }
    }
  }

  .service-email {
    position: relative;
    z-index: 0;
  }
  .service-phone {
    position: relative;
    z-index: 0;
    overflow: hidden !important;
  }

  .service-email::before {
    background: url("../../assets/images/vector-2.png");
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    display: block;
    width: 11.25rem;
    height: 9.578rem;
    top: 0;
    right: 0;
    position: absolute;
    z-index: -1;

    @media screen and (max-width: $sm-breakpoint) {
      width: 8.796rem !important;
      height: 8.313rem !important;
    }
  }

  .service-phone::before {
    background: url("../../assets/images/vector-3.png");
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    display: block;
    top: 11%;
    right: -2%;
    position: absolute;
    z-index: -1;
    width: 8.849rem;
    height: 8.448rem;

    @media screen and (max-width: $sm-breakpoint) {
      right: -8% !important;
      top: 16% !important;
      width: 9.589rem !important;
      height: 9.063rem !important;
    }
  }
}

.cancellation-modal-background {
  .cancellation-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;
    padding: 1rem;
    padding-bottom: 3rem;
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 1rem;
    text-align: center;
    max-width: 54.5625rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: $sm-breakpoint) {
      max-width: 90%;
      width: 90%;
      max-height: 90vh;
      overflow: hidden;
      .cancellation-form {
        flex: 1;
        overflow-y: auto;
        padding-bottom: 1rem;
      }
    }

    .contact-us {
      width: auto;
    }
  }
  .cancellation-modal::before {
    background-color: "#FEF9FF" !important;
  }
}
.new-cancel-plan-content * {
  font-family: "Elza", "Inter", sans-serif !important;
}

.new-cancel-plan-content {
  max-width: 100% !important;
  position: relative;
  z-index: 0;
  .new-padding-content-heading {
    font-size: 1.938rem;
    color: $dark-purple;
    padding: 0 1.875rem !important;
    font-weight: $font-weight-semibold;
    line-height: 2.325rem;

    @media screen and (max-width: $sm-breakpoint) {
      padding: 0 !important;
      font-size: $font-size-base;
    }
  }

  .padding-content {
    padding: 0 1.875rem !important;
    color: $dark-purple;
    text-align: left !important;
    font-size: 1.375rem;
    line-height: 1.65rem;

    @media screen and (max-width: $sm-breakpoint) {
      padding: 0 !important;
      font-size: 1rem;
      line-height: 1.125rem;
    }
  }

  .cancellation-next-btn {
    width: 17.8125rem !important;
    height: 50px !important;
    background-color: $purple !important;
    color: $white !important;
    font-size: 1.25rem;
    text-transform: capitalize !important;
    border-radius: 0.5rem !important;

    &.Mui-disabled {
      background-color: $lighter-purple-v3 !important;
      color: $purple !important;
    }

    &.cancel-plan {
      background-color: $white !important;
      color: $purple-v2 !important;
      border: 1px solid $purple-v2 !important;
    }

    @media screen and (max-width: $sm-breakpoint) {
      width: 15.8125rem !important;
    }

    @media screen and (max-width: $xs-breakpoint) {
      width: 14rem !important;
      font-size: 0.9rem !important;
    }
  }
}

.new-cancel-plan-content::before {
  background: url("../../assets/images/vector-9.png");
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  display: block;
  top: -8%;
  right: -4%;
  position: absolute;
  z-index: -1;
  width: 364px;
  height: 313px;
  @media screen and (max-width: $sm-breakpoint) {
    right: -10%;
  }
}

.new-select-form {
  .MuiSelect-select {
    display: flex !important;
    align-items: center !important;
    padding-right: 30px !important;
  }

  .MuiSvgIcon-root {
    color: #e5a8fb !important; // Adjust icon color
    font-size: 22px !important;
  }

  &.Mui-focused {
    border-color: #e5a8fb !important;
  }

  .MuiMenu-paper {
    border-radius: 8px !important;
    border: 1px solid #e5a8fb !important;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1) !important;
    overflow: hidden !important;
  }

  .MuiMenu-list {
    padding: 0 !important;
  }

  .MuiMenuItem-root {
    padding: 12px 16px !important;
    font-size: 14px !important;
    color: #1c1333 !important;
    transition: background 0.2s !important;

    &:hover {
      background-color: rgba(181, 126, 229, 0.1) !important;
    }
  }
}

.new-select-form {
  .MuiInputBase-root {
    color: $dark-purple !important;
    border-color: #c06df3 !important;
    border-width: 1px !important;
    border-style: solid !important;
    min-width: 120px !important;
    justify-content: center !important;
  }

  .MuiInputBase-root:hover {
    color: $dark-purple !important;
    border-color: #c06df3 !important;
    border-width: 1px !important;
  }

  .MuiSvgIcon-root {
    color: #e5a8fb !important; // Adjust icon color
    font-size: 22px !important;
  }

  &.Mui-focused {
    border-color: #e5a8fb !important;
  }

  .MuiMenu-paper {
    border-radius: 8px !important;
    border: 1px solid #e5a8fb !important;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1) !important;
    overflow: hidden !important;
  }

  .MuiMenu-list {
    padding: 0 !important;
  }

  .MuiMenuItem-root {
    padding: 12px 16px !important;
    font-size: 14px !important;
    color: #1c1333 !important;
    transition: background 0.2s !important;

    &:hover {
      background-color: rgba(181, 126, 229, 0.1) !important;
    }
  }
}
