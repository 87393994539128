.footer {
  min-height: $footer-height;
  background: $dark-purple;
  border-top: 0.45rem solid $lighter-border;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $md-breakpoint) {
    padding: 1rem 0;
  }

  .footer-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;

    @media screen and (max-width: $md-breakpoint) {
      padding: 1rem 0;
    }

    @media screen and (max-width: $sm-breakpoint) {
      margin-top: 0.2rem;
      margin-bottom: 0.2rem;
    }

    .footerlinks {
      padding: 0 !important;
      min-width: 0 !important;
      text-decoration: none;
      transition: 0.2s;
      transform-origin: center;
      text-transform: none !important;
      color: $white;
      font-size: $font-size-sm;

      transition: cubic-bezier(0.19, 1, 0.22, 1);
      &:hover {
        transform: scale(1.05);
      }
    }

    .footer-logo-container {
      max-width: 7.625rem;
      cursor: pointer;
      height: auto;
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: $sm-breakpoint) {
        max-width: 8rem;
      }

      .footer-logo {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .socialmedia {
      .socialmedia-icons {
        cursor: pointer;
        width: 1.625rem;
        height: 1.625rem;
        transition: 0.2s;

        @media screen and (max-width: $sm-breakpoint) {
          width: 1.50rem;
          height: 1.50rem;
        }

        &:hover {
          transform: scale(1.1);
        }
      }
    }
  }
}
