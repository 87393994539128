.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(24, 17, 47, 0.8); // Adjusted opacity
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 50;
  }
  
  .loading-text {
    color: white;
    margin-top: 1.25rem;
    font-size: 1.625rem;
    font-weight: 600;
    line-height: 1.9375rem;
    letter-spacing: 0;
    text-align: center;
  }